import {PropTypes} from "prop-types";
import useHTMLParser from "@/hooks/useHTMLParser";
import {forwardRef} from "react";

const HTMLParser = forwardRef(({html: str, ...rest}, ref) => {
  const parsedHTML = useHTMLParser(str);

  return (
    <div
      {...rest}
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: parsedHTML,
      }}
    ></div>
  );
});

HTMLParser.displayName = "HTMLParser";

HTMLParser.propTypes = {
  html: PropTypes.string.isRequired,
};

export default HTMLParser;
